:root {
  --font-color: #333;
  --highlight-one: #eee;
  --highlight-two: #ccc;
  --base: #fff;
}

.App {
  color: var(--font-color);
  text-align: center;
  font-size: 16pt;

  padding: 0;
  margin: 0;
}

.container {
  padding: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

button, .button  {
  background-color: var(--highlight-one);
  border: none;
  border-radius: 20px;
  padding: 20px;
  margin: 5px;
  font-size: inherit;

  transition: all 0.5s ease;
}

button:hover, .button:hover {
  background-color: var(--highlight-two);
}

.element {
  border: 3px solid var(--highlight-one);
  border-radius: 20px;
  margin: auto;
  padding: 20px;
  width: 90%;
  height: 90%;
  overflow: scroll;
  box-sizing: border-box;
  scrollbar-width: 0px;
}

/* PostWindow */

.window-bg {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.8);
}

.window {
  display: flex;
  width: 80%;
  height: 80%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: var(--base);
  border-radius: 10px;
  color: var(--font-color);
}

.window input {
  margin-bottom: 40px;
}

.vis {
  display: flex;
}

.invis {
  display: none;
}

.checkboxes {
  display: flex;
  flex-wrap: wrap;
}

.checkbox {
  margin: 10px;
}


/* App.js */
#open-menu {
  position: fixed;
  width: 75px;
  height: 75px;
  left: 0;
  background-color: transparent;

  transform: translateX(0);
  transition: 0.5s ease;
} 

#open-menu:before, #open-menu:after {
  content: "";
  display: block;
  width: 75%;
  border-top: 5px solid var(--font-color);
  border-radius: 5px;
}

#open-menu:before {
  transform: translate(0, -5px) rotate(45deg) !important;
}

#open-menu:after {
  transform: translate(0, 5px) rotate(-45deg) !important;
}

/* Menu.js */

#sidebar {
  position: fixed;
  background-color: var(--highlight-one);
  width: 100vw;
  height: 100vh;
  top: 0;
  
  transform: translateX(-100vw);
  transition: all 0.5s ease;
  
}

.menu-btn {
  border-radius: 0;
  width: 100%;
  margin: 0;

  transition: all 1s ease;
}

.menu-btn:hover, .menu-btn:active {
  background-color: var(--highlight-two);
}

.new {
  overflow: scroll;
  display: block;
}

.new h1 {
  display: block;
  height: 100px;
}

.new .button {
  display: inline-block;
}

.new input {
  border: none;
  border-radius: 20px;
  padding: 10px;
  background-color: var(--highlight-one);
  margin: 20px auto 20px auto;
  display: block;
}

/* Main.js */

#dataManipulation {
  margin: 0;
  width: 100%;
  height: 100vh;
}

.add {
  display: block;
  left: 0;
}

.house, .row {
  padding: 0;
  margin: 0;
  color: var(--highlight-two);
}

.house {
  display: flex;

  margin: 0 10px 0 10px;
  border-bottom: 3px solid var(--highlight-one);
}

.house-header {
  justify-content: center;
  padding: 10px;
  font-weight: bold;
}

.row:nth-child(even) {
  background-color: var(--highlight-one);
}

.row-header {
  font-weight: bold;
}

.table {
  display: flex;
}

.post {
  transition: background-color 0.5s ease;
}

.post:hover {
  background-color: var(--highlight-two);
}

.post-btn, .post-btn:hover {
  background-color: transparent;
  transition: none;
  padding: 5px;
}


.cell {
  border-radius: 0;
  background: transparent;
  height: 100%;
  width: 100%;
  padding: 20px 0 20px 0;
  margin: 0;
}

.red { background-color: rgb(255, 98, 98); }
.orange { background-color: rgb(255, 192, 98); }
.yellow { background-color: rgb(255, 239, 98); }
.green { background-color: rgb(108, 255, 98); }
.skyblue { background-color: rgb(98, 231, 255); }
.blue { background-color: rgb(98, 116, 255); }
.violet { background-color: rgb(190, 98, 255); }


